import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import Layout from "../components/layout";
import WidthLimit from "../components/width-limit";
import TextContent from "../components/text-content";

const NotFoundPage = () => (
	<Layout>
		<Helmet>
			<title>Page not found</title>
		</Helmet>
		<WidthLimit>
			<TextContent style={{ textAlign: "center" }}>
				<h1>Page not found</h1>
				<p>
					Return to <Link to="/">the homepage</Link>.
				</p>
			</TextContent>
		</WidthLimit>
	</Layout>
);

export default NotFoundPage;
