import React from "react";
import styled from "styled-components";

import Colours from "../constants/colours";

const TextContent = ({ children, ...otherProps }) => (
	<StyledTextContent {...otherProps}>{children}</StyledTextContent>
);

const StyledTextContent = styled.div`
	max-width: 900px;
	margin: 0 auto;
	padding: 100px 0 0;

	table {
		border-spacing: 0;
	}

	th,
	td {
		padding: 10px 20px;
	}

	tr {
		&:nth-child(even) {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}

	th {
		border-bottom: 3px double ${Colours.gold};
		font-weight: bold;
		text-align: left;
	}
`;

export default TextContent;
