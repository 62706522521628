import React from "react";
import styled, { css, keyframes } from "styled-components";

import Colours from "../constants/colours";
import Sizes, { min } from "../constants/sizes";

export default class ApplicationForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			actingExperience: "",
			actingExperienceDetail: "",
			agreeToTerms: false,
			city: "",
			commitToDates: "",
			currentBrokerage: "",
			currentlyLicensed: "",
			email: "",
			firstLicensed: "",
			headshotFilename: "",
			headshotUploaded: false,
			headshotUploading: false,
			headshotUploadProgress: 0,
			headshotUploadError: 0,
			marketingActivities: "",
			name: "",
			phone: "",
			postMeta: null,
			socialMedia: "",
			stokeLevel: "0",
			submitted: false,
			submitting: false,
			uniqueSkills: "",
			videoFilename: "",
			videoUploaded: false,
			videoUploading: false,
			videoUploadProgress: 0,
			videoUploadError: 0,
			willBeLicensedAtEndOfMarch: "",
		};
		this.postMetaPromise = null;
	}

	async getPostMeta() {
		if (this.state.postMeta || this.postMetaPromise) {
			return this.state.postMeta;
		}
		this.postMetaPromise = fetch(
			`${process.env.GATSBY_API_URL}/presigned-posts`
		);
		let response;
		try {
			response = await (await this.postMetaPromise).json();
		} catch (error) {
			this.postMetaPromise = null;
			throw error;
		}
		this.setState({
			postMeta: response,
		});
		return response;
	}

	handleChangeSimple = (event) => {
		const input = event.currentTarget;
		this.setState({
			[input.name]: input.value,
		});
	};

	handleChangeRadio = (event) => {
		const input = event.currentTarget;
		this.setState({
			[input.name]: input.value === "true",
		});
	};

	handleChangeCheckbox = (event) => {
		const input = event.currentTarget;
		this.setState({
			[input.name]: input.checked,
		});
	};

	handleChangeFile = async (event) => {
		const input = event.currentTarget;

		this.setState({
			[`${input.name}Filename`]: input.files.length
				? input.files[0].name
				: "",
		});

		if (input.files.length) {
			const file = input.files[0];
			const postMeta = await this.getPostMeta();
			const formData = new FormData();
			for (const [key, value] of Object.entries(
				postMeta.posts[input.name].fields
			)) {
				formData.append(key, value);
			}
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			const request = new XMLHttpRequest();

			const errorCase = () => {
				input.value = "";
				this.setState({
					[`${input.name}Uploaded`]: false,
					[`${input.name}Uploading`]: false,
					[`${input.name}UploadProgress`]: 0,
					[`${input.name}UploadError`]: true,
				});
			};

			request.addEventListener("load", () => {
				if (request.status < 200 || request.status >= 300) {
					errorCase();
				} else {
					this.setState({
						[`${input.name}Uploaded`]: true,
						[`${input.name}Uploading`]: false,
						[`${input.name}UploadProgress`]: 1,
						[`${input.name}UploadError`]: false,
					});
				}
			});
			request.addEventListener("error", errorCase);
			request.upload.addEventListener("progress", (event) => {
				this.setState({
					[`${input.name}Uploaded`]: false,
					[`${input.name}Uploading`]: true,
					[`${input.name}UploadProgress`]: event.loaded / event.total,
					[`${input.name}UploadError`]: false,
				});
			});
			request.open("POST", postMeta.posts[input.name].url);
			request.send(formData);
		}
	};

	handleSubmit = async (event) => {
		this.setState({
			submitting: true,
			submitted: false,
		});
		event.preventDefault();

		const errorCase = (message) => {
			this.setState({
				submitting: false,
				submitted: false,
			});
			// TODO: something prettier
			if (message) {
				alert(
					`There was a problem submitting your application: ${message}`
				);
			} else {
				alert(
					"There was a problem submitting your application. Please try again shortly."
				);
			}
		};

		let response;
		try {
			response = await (await fetch(
				`${process.env.GATSBY_API_URL}/application`,
				{
					method: "POST",
					mode: "cors",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						actingExperience: this.state.actingExperience,
						actingExperienceDetail: this.state.actingExperience
							? this.state.actingExperienceDetail
							: null,
						agreeToTerms: this.state.agreeToTerms,
						city: this.state.city,
						commitToDates: this.state.commitToDates,
						currentBrokerage: this.state.currentBrokerage,
						currentlyLicensed: this.state.currentlyLicensed,
						email: this.state.email,
						firstLicensed: this.state.firstLicensed,
						headshotFilename: this.state.headshotFilename,
						marketingActivities: this.state.marketingActivities,
						name: this.state.name,
						phone: this.state.phone,
						socialMedia: this.state.socialMedia,
						stokeLevel: this.state.stokeLevel,
						uniqueSkills: this.state.uniqueSkills,
						uploadPrefix: (this.state.postMeta || {}).prefix,
						videoFilename: this.state.videoFilename,
						willBeLicensedAtEndOfMarch: this.state
							.willBeLicensedAtEndOfMarch,
					}),
				}
			)).json();
		} catch (error) {
			console.error(error);
			errorCase();
			return;
		}

		if (!response.success) {
			console.error(response);
			errorCase(response.error);
			return;
		}

		this.setState({
			submitting: false,
			submitted: true,
		});
	};

	render() {
		const {
			actingExperience,
			actingExperienceDetail,
			agreeToTerms,
			city,
			commitToDates,
			currentBrokerage,
			currentlyLicensed,
			email,
			firstLicensed,
			headshotFilename,
			headshotUploaded,
			headshotUploading,
			headshotUploadProgress,
			headshotUploadError,
			marketingActivities,
			name,
			phone,
			socialMedia,
			stokeLevel,
			submitted,
			submitting,
			uniqueSkills,
			videoFilename,
			videoUploaded,
			videoUploading,
			videoUploadProgress,
			videoUploadError,
			willBeLicensedAtEndOfMarch,
		} = this.state;
		return (
			<FormContainer>
				{submitted ? (
					<SuccessBox>
						<div style={{ maxWidth: "40em", textAlign: "center" }}>
							<h3>Thank you for submitting your application!</h3>
							<p>
								Our team will review your application and may be
								in further contact.
							</p>
							<p>
								You will receive a confirmation email within a
								few hours. If after reviewing your junk mail
								folder you have not received a confirmation
								email within that time please let us know by
								sending an email to{" "}
								<a href="mailto:mark@markwiens.ca">
									mark@markwiens.ca
								</a>
								.
							</p>
							<p>Have a great day!</p>
						</div>
					</SuccessBox>
				) : (
					<form
						method="POST"
						className="Form"
						onSubmit={this.handleSubmit}
						autoComplete="on"
					>
						<FormCol style={{ width: "100%", textAlign: "center" }}>
							<h2>Apply now</h2>
						</FormCol>
						<FormCol>
							<Field label="Video" labelFor="af-video">
								<FileInput
									type="file"
									buttonText="Upload video"
									id="af-video"
									name="video"
									filename={videoFilename}
									accept="video/*"
									onChange={this.handleChangeFile}
									required
									uploaded={videoUploaded}
									uploading={videoUploading}
									uploadProgress={videoUploadProgress}
									uploadError={videoUploadError}
								/>
							</Field>
							<Field
								label="Current headshot"
								labelFor="af-headshot"
							>
								<FileInput
									type="file"
									buttonText="Upload image"
									id="af-headshot"
									name="headshot"
									filename={headshotFilename}
									accept="image/*"
									onChange={this.handleChangeFile}
									required
									uploaded={headshotUploaded}
									uploading={headshotUploading}
									uploadProgress={headshotUploadProgress}
									uploadError={headshotUploadError}
								/>
							</Field>
							<Field label="Name" labelFor="af-name">
								<TextInput
									type="text"
									onChange={this.handleChangeSimple}
									id="af-name"
									name="name"
									value={name}
									autoComplete="name"
									required
								/>
							</Field>
							<Field label="Phone number" labelFor="af-phone">
								<TextInput
									type="tel"
									onChange={this.handleChangeSimple}
									id="af-phone"
									name="phone"
									value={phone}
									autoComplete="tel"
									required
								/>
							</Field>
							<Field label="Email address" labelFor="af-email">
								<TextInput
									type="email"
									onChange={this.handleChangeSimple}
									id="af-email"
									name="email"
									value={email}
									autoComplete="email"
									required
								/>
							</Field>
							<Field label="City of residence" labelFor="af-city">
								<TextInput
									type="text"
									onChange={this.handleChangeSimple}
									id="af-city"
									name="city"
									value={city}
									autoComplete="address-level2"
									required
								/>
							</Field>
							<Field label="Are you currently a licensed REALTOR®?">
								<YesNo
									onChange={this.handleChangeRadio}
									name="currentlyLicensed"
									value={currentlyLicensed}
									required
								/>
							</Field>
							<Field label="By the end of March 2019 will you be a licensed Realtor?">
								<YesNo
									onChange={this.handleChangeRadio}
									name="willBeLicensedAtEndOfMarch"
									value={willBeLicensedAtEndOfMarch}
									required
								/>
							</Field>
							<Field
								label="In which year were you first licensed?"
								labelFor="af-firstLicensed"
							>
								<TextInput
									type="number"
									onChange={this.handleChangeSimple}
									id="af-firstLicensed"
									name="firstLicensed"
									value={firstLicensed}
									autoComplete="address-level2"
									step="1"
									min={1943 /* CREA founded in 1943 */}
									max={new Date().getFullYear()}
									style={{ width: "6em" }}
								/>
							</Field>
							<Field
								label="What is your current brokerage?"
								labelFor="af-currentBrokerage"
							>
								<TextInput
									type="text"
									onChange={this.handleChangeSimple}
									id="af-currentBrokerage"
									name="currentBrokerage"
									value={currentBrokerage}
									autoComplete="organization"
								/>
							</Field>
						</FormCol>
						<FormCol>
							<Field
								label={
									<>
										Are you able to commit to all{" "}
										<a href="#dates">dates of filming</a> as
										per the description?
									</>
								}
							>
								<YesNo
									onChange={this.handleChangeRadio}
									name="commitToDates"
									value={commitToDates}
									required
								/>
							</Field>
							<Field label="Have you acted on camera before?">
								<YesNo
									onChange={this.handleChangeRadio}
									name="actingExperience"
									value={actingExperience}
									required
								/>
							</Field>
							{actingExperience && (
								<Field
									label="Provide more details about your acting experience"
									labelFor="af-actingExperienceDetail"
								>
									<TextArea
										onChange={this.handleChangeSimple}
										id="af-actingExperienceDetail"
										name="actingExperienceDetail"
										value={actingExperienceDetail}
										rows={3}
										required
									/>
								</Field>
							)}
							<Field
								label="Tell us about your unique skills or abilities"
								labelFor="af-uniqueSkills"
							>
								<TextArea
									onChange={this.handleChangeSimple}
									id="af-uniqueSkills"
									name="uniqueSkills"
									rows={3}
									value={uniqueSkills}
								/>
							</Field>
							<Field
								label="What kind of marketing activities are you currently engaged in?"
								labelFor="af-marketingActivities"
							>
								<TextArea
									onChange={this.handleChangeSimple}
									id="af-marketingActivities"
									name="marketingActivities"
									value={marketingActivities}
									rows={3}
								/>
							</Field>
							<Field
								label="Please give links to your social media accounts"
								labelFor="af-socialMedia"
							>
								<TextArea
									onChange={this.handleChangeSimple}
									id="af-socialMedia"
									name="socialMedia"
									value={socialMedia}
									rows={3}
								/>
							</Field>
							<Field
								label="On a scale of 0&ndash;10, how stoked are you to participate in the show?"
								labelFor="af-stokeLevel"
							>
								<RangeInput
									onChange={this.handleChangeSimple}
									id="af-stokeLevel"
									name="stokeLevel"
									value={stokeLevel}
									min={0}
									step={1}
									max={10}
								/>
							</Field>

							<InlineField
								type="checkbox"
								label={
									<>
										I agree to the{" "}
										<a href="/release" target="_blank">
											authorization and release
										</a>{" "}
										terms
									</>
								}
								onChange={this.handleChangeCheckbox}
								id="af-agreeToTerms"
								name="agreeToTerms"
								value={false}
								checked={agreeToTerms}
								required
							/>

							<div>
								<Button
									type="submit"
									style={{ marginTop: "40px" }}
									disabled={submitting || submitted}
								>
									Apply now
								</Button>
							</div>
						</FormCol>
					</form>
				)}
			</FormContainer>
		);
	}
}

const FormContainer = styled.section`
	padding-top: 60px;

	${min.tablet`
		border: 1px solid ${Colours.gold};
		padding: ${Sizes.gutterElasticVw}vw 0;
	`}
	${min.desktop`
		.Form {
			display: flex;
			flex-wrap: wrap;
		}
	`}
	${min.max`
		padding: ${Sizes.gutterMaxPx}px 0;
	`}

	h2 {
		margin-top: 0;
	}

	.Form {
		${min.tablet`
			padding: 0 ${Sizes.gutterElasticVw / 2}vw;
		`}
		${min.max`
			padding: 0 ${Sizes.gutterMaxPx / 2}px;
		`}
	}
`;

const FormColImpl = ({ children, ...otherProps }) => (
	<div {...otherProps}>
		<div className="FormColInner">{children}</div>
	</div>
);

const FormCol = styled(FormColImpl)`
	box-sizing: border-box;

	${min.desktop`
		width: 50%;
	`}

	.FormColInner {
		${min.tablet`
			padding: 0 ${Sizes.gutterElasticVw / 2}vw;
		`}
		${min.desktop`
			padding: 0 ${Sizes.gutterMaxPx / 2}px;
		`}
	}
`;

const FieldImpl = ({ children, label, labelFor, ...otherProps }) => (
	<div {...otherProps}>
		<FieldLabel htmlFor={labelFor}>{label}</FieldLabel>
		{children}
	</div>
);

const Field = styled(FieldImpl)`
	margin: 25px 0;

	&:last-child {
		margin-bottom: 0;
	}
`;

const FieldLabel = styled.label`
	display: block;
	font-weight: 700;
	margin-bottom: 10px;
`;

const TextInput = styled.input`
	border: 0;
	border-radius: 0;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid ${Colours.gold};
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	color: white;
	font-size: inherit;
	font-family: inherit;

	&:focus {
		outline: 1px solid white;
		outline-offset: 2px;
	}
`;

const TextArea = styled.textarea`
	border: 0;
	border-radius: 0;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	resize: vertical;
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid ${Colours.gold};
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	color: white;
	font-size: inherit;
	font-family: inherit;

	&:focus {
		outline: 1px solid white;
		outline-offset: 2px;
	}
`;

const RangeInputImpl = ({ className, min, max, value, ...otherProps }) => (
	<span className={className}>
		<input
			className="RangeInputInput"
			type="range"
			min={min}
			max={max}
			value={value}
			{...otherProps}
		/>
		<span className={`RangeInputReadout stoked-${value}`}>
			<span>{value}</span>
		</span>
	</span>
);

const pulse = keyframes`
	0% {
		transform: scale(1.6);
		text-shadow: 0 0 2px rgba(${Colours.gold}, 0.9);
	}
	100% {
		transform: scale(2.2);
		text-shadow: 0 0 3px ${Colours.gold};
	}
`;

// These were randomly generated from (Math.random() - 0.5) * 3).toFixed(1)
const shake = keyframes`
	0% {
		transform: translate(-0.5px, -0.0px);
	}
	1% {
		transform: translate(1.4px, -0.1px);
	}
	2% {
		transform: translate(0.0px, 1.1px);
	}
	3% {
		transform: translate(-1.4px, 0.1px);
	}
	4% {
		transform: translate(-0.6px, 1.1px);
	}
	5% {
		transform: translate(-1.4px, 0.7px);
	}
	6% {
		transform: translate(0.8px, -0.5px);
	}
	7% {
		transform: translate(-0.8px, 1.4px);
	}
	8% {
		transform: translate(0.9px, 0.2px);
	}
	9% {
		transform: translate(-1.3px, 1.3px);
	}
	10% {
		transform: translate(-0.6px, 1.1px);
	}
	11% {
		transform: translate(0.6px, -0.8px);
	}
	12% {
		transform: translate(0.1px, -0.7px);
	}
	13% {
		transform: translate(-1.4px, 0.7px);
	}
	14% {
		transform: translate(-0.1px, -0.8px);
	}
	15% {
		transform: translate(-0.5px, -1.4px);
	}
	16% {
		transform: translate(0.4px, -1.2px);
	}
	17% {
		transform: translate(-0.1px, 1.4px);
	}
	18% {
		transform: translate(-0.6px, 0.5px);
	}
	19% {
		transform: translate(0.1px, -0.7px);
	}
	20% {
		transform: translate(0.3px, -1.1px);
	}
	21% {
		transform: translate(-0.8px, 0.5px);
	}
	22% {
		transform: translate(0.2px, -1.3px);
	}
	23% {
		transform: translate(1.1px, -1.5px);
	}
	24% {
		transform: translate(-1.0px, 0.8px);
	}
	25% {
		transform: translate(0.9px, 0.6px);
	}
	26% {
		transform: translate(1.1px, 0.3px);
	}
	27% {
		transform: translate(1.4px, 1.4px);
	}
	28% {
		transform: translate(0.5px, -1.5px);
	}
	29% {
		transform: translate(-0.6px, 1.4px);
	}
	30% {
		transform: translate(1.2px, 1.3px);
	}
	31% {
		transform: translate(-0.4px, -0.4px);
	}
	32% {
		transform: translate(0.5px, 0.0px);
	}
	33% {
		transform: translate(-1.4px, 0.5px);
	}
	34% {
		transform: translate(0.1px, 0.9px);
	}
	35% {
		transform: translate(-0.7px, 0.5px);
	}
	36% {
		transform: translate(1.0px, 0.2px);
	}
	37% {
		transform: translate(-0.3px, -0.7px);
	}
	38% {
		transform: translate(-0.4px, -1.1px);
	}
	39% {
		transform: translate(-0.1px, 1.2px);
	}
	40% {
		transform: translate(0.4px, -0.8px);
	}
	41% {
		transform: translate(0.4px, 0.6px);
	}
	42% {
		transform: translate(-0.9px, 1.2px);
	}
	43% {
		transform: translate(-0.7px, -0.9px);
	}
	44% {
		transform: translate(-0.4px, -1.4px);
	}
	45% {
		transform: translate(-0.1px, -1.2px);
	}
	46% {
		transform: translate(0.9px, 0.6px);
	}
	47% {
		transform: translate(-1.4px, 0.0px);
	}
	48% {
		transform: translate(-0.5px, 1.0px);
	}
	49% {
		transform: translate(1.3px, 1.4px);
	}
	50% {
		transform: translate(-0.7px, 1.1px);
	}
	51% {
		transform: translate(1.3px, -0.4px);
	}
	52% {
		transform: translate(1.5px, 0.5px);
	}
	53% {
		transform: translate(0.5px, -1.3px);
	}
	54% {
		transform: translate(1.4px, 0.8px);
	}
	55% {
		transform: translate(0.7px, -0.2px);
	}
	56% {
		transform: translate(-1.0px, -0.4px);
	}
	57% {
		transform: translate(0.9px, 1.5px);
	}
	58% {
		transform: translate(1.4px, 0.3px);
	}
	59% {
		transform: translate(0.3px, -1.2px);
	}
	60% {
		transform: translate(-1.1px, 0.5px);
	}
	61% {
		transform: translate(0.3px, -0.0px);
	}
	62% {
		transform: translate(-1.2px, -0.5px);
	}
	63% {
		transform: translate(-0.5px, 0.2px);
	}
	64% {
		transform: translate(1.2px, 0.7px);
	}
	65% {
		transform: translate(0.9px, -1.0px);
	}
	66% {
		transform: translate(-0.4px, -0.4px);
	}
	67% {
		transform: translate(-1.0px, 0.5px);
	}
	68% {
		transform: translate(0.8px, -0.4px);
	}
	69% {
		transform: translate(0.1px, 1.4px);
	}
	70% {
		transform: translate(0.2px, 0.6px);
	}
	71% {
		transform: translate(1.1px, 0.7px);
	}
	72% {
		transform: translate(1.0px, -0.7px);
	}
	73% {
		transform: translate(-0.2px, -1.3px);
	}
	74% {
		transform: translate(0.6px, 1.0px);
	}
	75% {
		transform: translate(0.1px, 0.4px);
	}
	76% {
		transform: translate(-0.8px, 0.3px);
	}
	77% {
		transform: translate(0.9px, -0.3px);
	}
	78% {
		transform: translate(-0.4px, -1.4px);
	}
	79% {
		transform: translate(-0.4px, 0.3px);
	}
	80% {
		transform: translate(1.2px, -0.0px);
	}
	81% {
		transform: translate(0.8px, 0.3px);
	}
	82% {
		transform: translate(-1.5px, -0.2px);
	}
	83% {
		transform: translate(-0.4px, 0.8px);
	}
	84% {
		transform: translate(-0.0px, 0.9px);
	}
	85% {
		transform: translate(-0.3px, -1.0px);
	}
	86% {
		transform: translate(-0.1px, 0.1px);
	}
	87% {
		transform: translate(0.2px, 1.3px);
	}
	88% {
		transform: translate(-1.5px, 0.7px);
	}
	89% {
		transform: translate(1.4px, -0.9px);
	}
	90% {
		transform: translate(0.2px, 0.2px);
	}
	91% {
		transform: translate(-1.3px, 1.0px);
	}
	92% {
		transform: translate(1.2px, -0.7px);
	}
	93% {
		transform: translate(-0.9px, -1.4px);
	}
	94% {
		transform: translate(-0.8px, -0.6px);
	}
	95% {
		transform: translate(-1.4px, -1.5px);
	}
	96% {
		transform: translate(1.1px, -1.1px);
	}
	97% {
		transform: translate(-0.7px, -0.2px);
	}
	98% {
		transform: translate(-1.0px, -1.3px);
	}
	99% {
		transform: translate(-1.0px, -0.1px);
	}
	100% {
		transform: translate(1.1px, -1.4px);
	}
`;

// Adapted from https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/#article-header-id-4
const RangeInput = styled(RangeInputImpl)`
	display: flex;
	align-items: center;

	.RangeInputReadout {
		width: 2rem;
		margin-left: 2rem;
		text-align: center;
		vertical-align: middle;
		transition: all 0.3s;

		&.stoked-7 {
			transform: scale(1.1);
		}
		&.stoked-8 {
			transform: scale(1.3);
			text-shadow: 0 0 1px ${Colours.gold};
		}
		&.stoked-9 {
			transform: scale(1.6);
			text-shadow: 0 0 2px ${Colours.gold};
		}
		&.stoked-10 {
			transform: scale(1.6);
			animation: ${pulse} 0.3s ease-in-out infinite alternate;
			span {
				display: inline-block;
				animation: ${shake} 2.5s linear infinite;
			}
		}
	}

	.RangeInputInput {
		-webkit-appearance: none;
		margin: 18px 0;
		width: 100%;
		background: transparent;

		&:focus {
			outline: none;
		}

		&::-moz-focus-outer {
			border-style: none;
		}

		&::-webkit-slider-runnable-track {
			width: 100%;
			height: 1px;
			box-shadow: none;
			background: transparent
				linear-gradient(
					to right,
					transparent,
					transparent 6px,
					white 6px,
					${Colours.gold} calc(100% - 6px),
					transparent calc(100% - 6px)
				);
			border: none;
		}
		&::-webkit-slider-thumb {
			box-shadow: none;
			height: 25px;
			width: 12px;
			background: ${Colours.gold};
			box-shadow: 0 0 10px black;
			cursor: pointer;
			-webkit-appearance: none;
			position: relative;
			top: -12px;
			box-sizing: content-box;
		}
		&:focus::-webkit-slider-thumb {
			outline: 1px solid white;
			outline-offset: 2px;
		}
		&::-moz-range-track {
			height: 2px;
			box-shadow: none;
			background: transparent
				linear-gradient(
					to right,
					transparent,
					transparent 6px,
					white 6px,
					${Colours.gold} calc(100% - 6px),
					transparent calc(100% - 6px)
				);
			border: none;
		}
		&::-moz-range-thumb {
			border: none;
			height: 24px;
			width: 12px;
			background-color: ${Colours.gold};
			box-shadow: 0 0 10px black;
			border-radius: 0;
			cursor: pointer;
		}
		&:focus::-moz-range-thumb {
			outline: 1px solid white;
			outline-offset: 2px;
		}
		&::-ms-track {
			width: 100%;
			height: 2px;
			background: transparent;
			border-color: transparent;
			border-width: 12px 0;
			color: transparent;
		}
		&::-ms-fill-lower {
			background: ${Colours.gold};
			border: none;
			border-radius: 0;
			box-shadow: none;
		}
		&::-ms-fill-upper {
			background: white;
			border: none;
			border-radius: 0;
			box-shadow: none;
		}
		&::-ms-thumb {
			box-shadow: 0 0 10px black;
			border: none;
			height: 24px;
			width: 12px;
			border-radius: 0;
			background: ${Colours.gold};
			cursor: pointer;
		}
		&:focus::-ms-thumb {
			outline: 1px solid white;
			outline-offset: 2px;
		}
	}
`;

const InlineFieldImpl = ({
	type,
	className,
	value,
	checked,
	label,
	...otherProps
}) => (
	<label className={className}>
		<input
			className="InlineFieldInput"
			type={type}
			value={value}
			checked={checked}
			{...otherProps}
		/>
		<span className="InlineFieldFake" />
		{label}
	</label>
);

const InlineField = styled(InlineFieldImpl)`
	position: relative;
	margin-right: 20px;

	.InlineFieldInput {
		position: absolute;
		opacity: 0;

		&:checked + .InlineFieldFake::after {
			opacity: 1;
		}

		&:focus + .InlineFieldFake {
			outline: 1px solid white;
			outline-offset: 2px;
		}

		${(props) =>
			props.type === "radio" &&
			css`
				&:focus + .InlineFieldFake {
					outline: none;
				}

				&:focus + .InlineFieldFake::before {
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					border: 1px solid white;
					box-sizing: content-box;
					padding: 4px;
					left: -5px;
					top: -5px;
					border-radius: ${(props) =>
						props.type === "radio" ? "50%" : "0"};
				}
			`}
	}

	.InlineFieldFake {
		display: inline-block;
		width: 1.25em;
		height: 1.25em;
		border: 2px solid ${Colours.gold};
		border-radius: ${(props) => (props.type === "radio" ? "50%" : "0")};
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
		background-color: rgba(0, 0, 0, 0.2);
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);

		&::after {
			content: "";
			position: absolute;
			top: 3px;
			left: 3px;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			border-radius: inherit;
			background-color: ${Colours.gold};
			opacity: 0;
		}
	}
`;

const YesNo = ({ value, ...otherProps }) => (
	<>
		<InlineField
			type="radio"
			value={false}
			label="No"
			checked={value === false}
			{...otherProps}
		/>
		<InlineField
			type="radio"
			value={true}
			label="Yes"
			checked={value === true}
			{...otherProps}
		/>
	</>
);

// Add some strategic zero-width spaces so it might break at reasonable places
const addZws = (filename) =>
	filename
		.replace(/([.-_/])/g, "\u200b$1")
		.replace(/([a-z])([A-Z])/g, "$1\u200b$2");

const FileInputImpl = ({
	className,
	buttonText,
	filename,
	uploaded,
	uploading,
	uploadProgress,
	uploadError,
	...otherProps
}) => (
	<div className={className}>
		<input className="FileInputInput" {...otherProps} />
		<Button className="FileInputButton" type="button" tabIndex="-1">
			{buttonText ? buttonText : "Upload"}
		</Button>
		{uploading ? (
			<span className="FileInputFilename">
				Uploading {addZws(filename)}: {Math.round(uploadProgress * 100)}
				%&hellip;
			</span>
		) : null}
		{uploaded ? (
			<span className="FileInputFilename">
				Uploaded {addZws(filename)}
			</span>
		) : null}
		{uploadError ? (
			<span className="FileInputFilename">
				Error uploading {addZws(filename)}
			</span>
		) : null}
	</div>
);

const FileInput = styled(FileInputImpl)`
	position: relative;
	display: flex;
	align-items: center;

	&:focus-within .FileInputButton {
		/* For Firefox */
		outline: 1px solid white;
		outline-offset: 2px;
	}

	.FileInputInput {
		position: absolute;
		opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&:focus + .FileInputButton {
			outline: 1px solid white;
			outline-offset: 2px;
		}

		&:hover + .FileInputButton {
			background-color: ${Colours.gold};
			color: ${Colours.navy};
		}
	}

	.FileInputButton {
		flex-shrink: 0;
	}

	.FileInputFilename {
		margin-left: 20px;
	}
`;

const Button = styled.button`
	border: none;
	border-radius: 0;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	color: ${Colours.gold};
	border: 1px solid ${Colours.gold};
	font-weight: 900;
	font-size: 0.9em;
	padding: 15px 25px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	transition: background-color 0.2s, color 0.2s;

	&:hover {
		background-color: ${Colours.gold};
		color: ${Colours.navy};
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:focus {
		outline: 1px solid white;
		outline-offset: 2px;
	}
`;

const SuccessBox = styled.div`
	display: flex;
	min-height: 400px;
	align-items: center;
	justify-content: center;
`;
