import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

import { title, description } from "../constants/meta";
import Colours from "../constants/colours";

import draccoLogo from "../images/dracco-logo.svg";
import shareImage from "../images/share-image.png";

import Logo from "./logo";
import WidthLimit from "./width-limit";

const GlobalStyle = createGlobalStyle`
	html {
		background-color: ${Colours.almostBlack};
		color: white;
		font-family: Lato, sans-serif;
		line-height: 1.5;
	}
	body {
		margin: 0;
	}
	h1, h2 {
		font-size: 2.2rem;
		text-transform: uppercase;
		font-weight: 300;
		letter-spacing: 0.1em;
	}
	h2 {
		font-size: 1.8rem;
	}
	a {
		color: ${Colours.gold};

		&:focus {
			outline: 1px solid white;
			outline-offset: 1px;
		}
	}
`;

const Layout = ({ children }) => (
	<>
		<Helmet
			htmlAttributes={{ lang: "en" }}
			defaultTitle={title}
			titleTemplate={`%s | ${title}`}
		>
			<meta charSet="utf-8" />
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={shareImage} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta property="twitter:card" content="summary" />
		</Helmet>

		<GlobalStyle />

		{children}

		<Footer>
			<WidthLimit>
				<ShareIcons>
					<a href="https://www.facebook.com/mwiensrealty/">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							aria-label="Facebook"
							role="img"
							viewBox="0 0 512 512"
						>
							<rect
								width="512"
								height="512"
								rx="15%"
								fill="#3b5998"
							/>
							<path
								fill="#fff"
								d="M330 512V322h64l9-74h-73v-47c0-22 6-36 37-36h39V99c-7-1-30-3-57-3-57 0-95 34-95 98v54h-64v74h64v190z"
							/>
						</svg>
					</a>
					<a href="https://www.instagram.com/markwiensrealestate/">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							aria-label="Instagram"
							role="img"
							viewBox="0 0 512 512"
						>
							<rect
								width="512"
								height="512"
								rx="15%"
								fill="#d43377"
							/>
							<g fill="none" stroke="#fff" strokeWidth="29">
								<rect
									height="296"
									rx="78"
									width="296"
									x="108"
									y="108"
								/>
								<circle cx="256" cy="256" r="69" />
							</g>
							<circle cx="343" cy="169" fill="#fff" r="19" />
						</svg>
					</a>
					<a href="https://www.youtube.com/channel/UCajXkW6et8TSV8lNTAxadDA">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							aria-label="YouTube"
							role="img"
							viewBox="0 0 512 512"
							fill="#ed1d24"
						>
							<rect width="512" height="512" rx="15%" />
							<path
								d="m427 169c-4-15-17-27-32-31-34-9-239-10-278 0-15 4-28 16-32 31-9 38-10 135 0 174 4 15 17 27 32 31 36 10 241 10 278 0 15-4 28-16 32-31 9-36 9-137 0-174"
								fill="#fff"
							/>
							<path d="m220 203v106l93-53" />
						</svg>
					</a>
				</ShareIcons>
				<div>
					<a
						href="http://markwiens.ca/"
						aria-label="Mark Wiens’s website"
					>
						<Logo style={{ color: Colours.gold }} />
					</a>
					<img
						alt="Dracco Pacific Realty"
						src={draccoLogo}
						width="180"
						height="103"
					/>
				</div>
				<p>
					Mark Wiens does business as Mark Wiens Personal Real Estate
					Corporation and is licensed with Dracco Pacific Realty. This
					communication is not intended to induce a breach of an
					existing agency relationship.
				</p>
				<p>
					The trademarks REALTOR®, REALTORS®, and the REALTOR® logo
					are controlled by The Canadian Real Estate Association
					(CREA) and identify real estate professionals who are
					members of CREA.
				</p>
			</WidthLimit>
		</Footer>
	</>
);

const Footer = styled.footer`
	padding: 100px 0 50px;
	color: rgba(255, 255, 255, 0.75);
	text-align: center;
	font-weight: 300;
	max-width: 800px;
	margin: 0 auto;
	font-size: 0.9rem;
	line-height: 1.1;
	position: relative;
`;

const ShareIcons = styled.div`
	margin-bottom: 4em;

	a {
		margin: 0 0.5em;
		display: inline-block;
	}

	svg {
		display: block;
		width: 3em;
		height: 3em;
	}
`;

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
