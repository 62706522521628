import React from "react";
import styled from "styled-components";

import { graphql } from "gatsby";
import Img from "gatsby-image";

import ApplicationForm from "../components/application-form";
import Details from "../components/details";
import Layout from "../components/layout";
import WidthLimit from "../components/width-limit";
import TextContent from "../components/text-content";

import Colours from "../constants/colours";
import Sizes, { min } from "../constants/sizes";

import logoBg from "../images/logo-bg.svg";

const IndexPage = ({
	data: {
		iWantYou: { childImageSharp: iWantYou },
		burrardWide: { childImageSharp: burrardWide },
		burrardNarrow: { childImageSharp: burrardNarrow },
	},
}) => (
	<Layout>
		<Header heroAspect={iWantYou.fluid.aspectRatio}>
			<div className="HeaderContent">
				<div className="HeaderText">
					<h1>Vancouver’s Next Top Agent</h1>
					<p>Do you have what it takes?</p>
				</div>

				<div className="HeaderVideo">
					<div
						style={{
							padding: "56.25% 0 0 0",
							position: "relative",
						}}
					>
						<iframe
							title="Promotional video"
							src="https://www.youtube.com/embed/R0vU-xmi76U?modestbranding=1&rel=0"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</div>
				</div>
			</div>

			<Img
				className="HeaderImage"
				fluid={iWantYou.fluid}
				alt="Mark Wiens in a suit and pointing at you."
			/>
		</Header>

		<MainArea>
			<WidthLimit>
				<IndexContent>
					<div className="IndexContentIntro">
						<p style={{ color: Colours.gold, fontSize: "140%" }}>
							<strong>Yes</strong>, Mark Wiens is creating a
							reality TV show.{" "}
						</p>
						<p>
							If you are a REALTOR® looking to join a dynamic real
							estate team, and to show the world your skills, you
							should apply! It all comes down to if you have what
							it takes to become Vancouver’s Next Top Agent!
						</p>
					</div>

					<Details />
				</IndexContent>
			</WidthLimit>
		</MainArea>

		<FormArea>
			<FormBackground
				thumbnail={burrardWide.fluid.base64}
				thumbnailPortrait={burrardNarrow.fluid.base64}
			>
				<picture>
					<source
						media={`(min-width: ${Sizes.desktop}px)`}
						srcSet={burrardWide.fluid.srcSet}
						sizes={burrardWide.fluid.sizes}
					/>
					<img
						src={burrardNarrow.fluid.src}
						srcSet={burrardNarrow.fluid.srcSet}
						width={burrardNarrow.fluid.presentationWidth}
						height={burrardNarrow.fluid.presentationHeight}
						sizes="100vw"
						alt=""
					/>
				</picture>
			</FormBackground>
			<WidthLimit style={{ position: "relative" }}>
				<ApplicationForm />
			</WidthLimit>
		</FormArea>
	</Layout>
);

const Header = styled.header`
	position: relative;

	&::after {
		content: "";
		position: absolute;
		background-color: rgba(0, 0, 0, 0.3);
		left: 0;
		bottom: 0;
		right: 0;
		height: 50px;
	}

	${min.desktop`
		height: ${(props) => `${100 / props.heroAspect}%`};
		@supports(object-fit: cover) {
			height: 100vh;
			max-height: 900px;
		}
	`}

	.HeaderImage {
		${min.desktop`
			width: 100%;
			height: 100%;
		`}
	}

	.HeaderContent {
		position: absolute;
		width: 45%;
		top: 50%;
		left: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}

	.HeaderText {
		text-align: right;
		h1 {
			font-size: 4.2vw;
			line-height: 1.1;
			margin: 0;
			text-transform: uppercase;

			${min.tablet`
				font-size: 2.45vw;
			`}
		}
		p {
			font-size: 3.2vw;
			font-weight: 300;
			margin: 0;

			${min.tablet`
				font-size: 2vw;
			`}

			${min.desktop`
				font-size: 1.4vw;
			`}
		}
		margin-bottom: 30px;
	}

	.HeaderVideo {
		border-radius: 10px;
		overflow: hidden;
		box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.5);
	}
`;

const IndexContent = styled(TextContent)`
	padding-top: 40px;

	.IndexContentIntro {
		font-size: 1.5em;
		margin-bottom: 80px;
	}
`;

const MainArea = styled.div`
	background-color: ${Colours.navy};
	background-image: url(${logoBg});
	background-size: 280% auto;
	background-position: 70% -250px;
	padding-bottom: 80px;
`;

const FormArea = styled.div`
	background-color: ${Colours.almostBlack};
	padding: ${Sizes.gutterElasticVw}vw 0;
	position: relative;
	overflow: hidden;

	${min.max`
		padding: ${Sizes.gutterMaxPx * 2}px 0;
	`}
`;

const FormBackground = styled.div`
	background-image: url(${(props) => props.thumbnailPortrait});
	background-size: cover;
	opacity: 0.2;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	max-height: 100%;
	mask-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 80%,
		rgba(0, 0, 0, 0)
	);
	pointer-events: none;

	${min.desktop`
		background-image: url(${(props) => props.thumbnail});
	`}

	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

export default IndexPage;

export const query = graphql`
	query {
		iWantYou: file(relativePath: { eq: "Iwantyouheadshot.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2160, quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		burrardWide: file(relativePath: { eq: "burrard.crop.squarish.jpg" }) {
			childImageSharp {
				fluid(
					maxWidth: 2160
					quality: 80
					srcSetBreakpoints: [992, 1280]
				) {
					srcSet
					src
					base64
					sizes
					presentationWidth
					presentationHeight
				}
			}
		}
		burrardNarrow: file(relativePath: { eq: "burrard.crop.portrait.jpg" }) {
			childImageSharp {
				fluid(
					sizes: "100vw"
					maxWidth: 1536
					quality: 80
					srcSetBreakpoints: [320, 640, 720, 992, 1280]
				) {
					srcSet
					src
					base64
					sizes
					presentationWidth
					presentationHeight
				}
			}
		}
	}
`;
