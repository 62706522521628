import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Sizes, { min } from "../constants/sizes";

const Outer = styled.div`
	max-width: ${Sizes.max}px;
	margin-left: auto;
	margin-right: auto;
`;

const Inner = styled.div`
	padding-left: ${Sizes.gutterElasticVw}vw;
	padding-right: ${Sizes.gutterElasticVw}vw;

	${min.max`
    padding-left: ${Sizes.gutterMaxPx}px;
    padding-right: ${Sizes.gutterMaxPx}px;
  `}
`;

/**
 * WidthLimit
 *
 * Wrap this around some content to set the stretchy side padding and site-wide
 * maximum width.
 */
export default class WidthLimit extends React.Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
		unpadded: PropTypes.bool.isRequired,
	};
	static defaultProps = {
		unpadded: false,
	};

	render() {
		const { children, unpadded, ...otherProps } = this.props;
		const padded = !unpadded;

		if (padded) {
			return (
				<Outer {...otherProps}>
					<Inner>{children}</Inner>
				</Outer>
			);
		}

		return <Outer {...otherProps}>{children}</Outer>;
	}
}
