import React from "react";
import { graphql, StaticQuery } from "gatsby";

const Details = (data) => (
	<div dangerouslySetInnerHTML={{ __html: data.details.html }} />
);

const detailsQuery = graphql`
	query DetailsQuery {
		details: markdownRemark(
			fileAbsolutePath: { regex: "//data/markdown/details.md$/" }
		) {
			html
		}
	}
`;

export default () => <StaticQuery query={detailsQuery} render={Details} />;
