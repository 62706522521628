import React from "react";

const Logo = ({ className, style }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="111.4"
		height="106.309"
		className={className}
		style={style}
	>
		<path
			d="M88.255 62.005l.18 31.254-2.303-28.344zM59.82 99.069L83.267 54.76l.938 9.15zM28.135 54.76l23.447 44.31-24.385-35.16zm-5.168 38.5l.18-31.254 2.123 2.91zm-2.135 4.554l-15.64-60.43 8.993 12.332zm.296-38.577l.642 36.548-4.727-42.148zM14.222 28.47l6.584 12.444.249 14.142-4.595-6.624zm12.144 22.95l-.821 10.11-2.375-3.424.072-12.59zm-2.975-31.73l4.551 12.327-1.206 14.843-3.461-6.923zm28.885 78.24L28.57 50.525l1.348-13.155zM32.621 11.02l20.374 85.504-22.457-65.2zm25.787 85.504L78.782 11.02l2.081 20.304zm24.424-46L59.126 97.931 81.483 37.37zm5.179-30.836l.116 20.247-3.461 6.923-1.206-14.843zm.221 38.416l-2.375 3.424-.821-10.11 3.124-5.904zm8.948-29.636L94.942 48.43l-4.595 6.624.249-14.142zm-7.548 67.315l.641-36.548 4.085-5.6zm16.578-58.4l-15.64 60.43 6.647-48.098zm4.511-8.944a1.576 1.576 0 0 0-2.193.403l-10.523 15.17 3.241-23.447a1.58 1.58 0 0 0-.859-1.626 1.575 1.575 0 0 0-2.114.708l-7.57 15.137.418-23.794a1.577 1.577 0 0 0-3.069-.54L82.92 25.358 80.977 1.45a1.577 1.577 0 0 0-3.115-.205L55.701 103.6 33.54 1.245a1.579 1.579 0 0 0-3.115.205l-1.941 23.908L23.35 10.45a1.577 1.577 0 0 0-3.069.54l.418 23.794-7.57-15.137a1.577 1.577 0 0 0-2.973.919l3.241 23.447-10.523-15.17a1.575 1.575 0 0 0-2.811 1.333l21.9 75.789c.074.256.307.344.51.34.204-.004.4-.241.42-.421l3.976-38.775 28.316 38.828a.636.636 0 0 0 .505.249c.2.004.4-.077.52-.242l28.323-38.836 3.977 38.775a.481.481 0 0 0 .494.417.49.49 0 0 0 .434-.335l21.902-75.79a1.585 1.585 0 0 0-.619-1.735"
			fill="currentColor"
		/>
	</svg>
);

export default Logo;
