import { css } from "styled-components";

/**
 * MAX_WIDTH_PX
 *
 * This is the viewport width where things stop stretching.
 * It is not the maximum width of the content area;
 * that would be `MAX_WIDTH_PX - GUTTER_MAX_PX * 2`.
 */
export const MAX_WIDTH_PX = 1230;

/**
 * GUTTER_MAX_PX
 *
 * This is the gutter width when the viewport is at least MAX_WIDTH_PX.
 */
export const GUTTER_MAX_PX = 30;

/**
 * GUTTER_ELASTIC_VW
 *
 * This is the gutter width when the viewport is narrower than MAX_WIDTH_PX.
 */
export const GUTTER_ELASTIC_VW = (100 * GUTTER_MAX_PX) / MAX_WIDTH_PX;

const sizes = {
	gutterMaxPx: GUTTER_MAX_PX,
	gutterElasticVw: GUTTER_ELASTIC_VW,

	// Minimum widths
	tablet: 768,
	desktop: 992,
	max: MAX_WIDTH_PX,
};
export default sizes;

/**
 * min
 *
 * This is a set of template tags used to add media queries to styled components
 * CSS.
 *
 * - min.tablet: make a media query for tablet sizes and greater
 * - min.desktop: make a media query for desktop sizes and greater
 * - min.max: make a media query for sizes at and beyond MAX_WIDTH_PX
 *
 * Usage:
 *
 *     import { min } from 'constants/sizes.js'
 *     css`
 *       width: 30px;
 *       ${min.tablet}`
 *         width: 40px;
 *       `}
 *     `
 */
export const min = ["tablet", "desktop", "max"].reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(...args)}
		}
	`;
	return acc;
}, {});
