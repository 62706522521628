module.exports = [{
      plugin: require('/home/bjn/clients/markwiens/vancouversnexttoprealtor.ca/web/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('/home/bjn/clients/markwiens/vancouversnexttoprealtor.ca/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132800188-1","respectDNT":true},
    },{
      plugin: require('/home/bjn/clients/markwiens/vancouversnexttoprealtor.ca/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
