import React from "react";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import WidthLimit from "../components/width-limit";
import TextContent from "../components/text-content";

const ReleasePage = (data) => (
	<Layout>
		<Helmet>
			<title>{data.release.frontmatter.title}</title>
		</Helmet>
		<WidthLimit>
			<TextContent>
				<h1>{data.release.frontmatter.title}</h1>
				<div dangerouslySetInnerHTML={{ __html: data.release.html }} />
			</TextContent>
		</WidthLimit>
	</Layout>
);

const releaseQuery = graphql`
	query ReleaseQuery {
		release: markdownRemark(
			fileAbsolutePath: { regex: "//data/markdown/release.md$/" }
		) {
			frontmatter {
				title
			}
			html
		}
	}
`;

export default () => <StaticQuery query={releaseQuery} render={ReleasePage} />;
